import React from 'react';

// Hooks
import useFooter from "../../hooks/ui/useFooter";

//components
import Button from '../button';

function Footer() {
  const { description, logo, socialTitle, twitterImg, twitterLink, instagramImg, instagramLink, linkedinImg, linkedinLink, copyright, privacyLink, termsLink } = useFooter();
  
  return (
        <footer className={'px-3 xlg:px-0  w-full flex flex-col bg-green-mustard'}>
            <div className={'max-w-screen-2xl m-auto flex w-full flex-wrap justify-center xl:justify-between pt-12 md:pt-16 pb-20 md:px-36 px-5 md:pb-40 gap-x-20 gap-y-20'}>
                <img className={'w-26 h-15 object-center object-contain'} src={logo?.fields?.file.url} alt={logo?.fields?.title} />
                <p className={'w-64 text-center xlg:text-left text-xs text-gray-base font-gr'}>{description}</p>
                <div className={'flex flex-col justify-start xlg:items-start items-center xlg'}>
                    <div className={'text-gray-base text-sm uppercase font-gm'}>{socialTitle}</div>
                    <div className={'flex items-center gap-7 sm:gap-5 w-full mt-7 sm:mt-3.5'}>
                      {twitterLink &&
                        <a href={twitterLink} target='_blank' rel="noreferrer">
                            <img className={'w-5 object-center object-contain'} src={twitterImg?.fields?.file.url} alt={twitterImg?.fields?.title} />
                        </a>
                      }
                      {instagramLink &&
                        <a href={instagramLink} target='_blank' rel="noreferrer">
                            <img className={'w-4.5 object-center object-contain'} src={instagramImg?.fields?.file.url} alt={instagramImg?.fields?.title} />
                        </a>
                      }
                      {linkedinLink &&
                        <a href={linkedinLink} target='_blank' rel="noreferrer">
                            <img className={'w-4.5 object-center object-contain'} src={linkedinImg?.fields?.file.url} alt={linkedinImg?.fields?.title} />
                        </a>
                      }
                    </div>
                </div>
                {/* <form className={'md:w-86 w-full mt-5 sm:mt-0'}>
                    <label className={'text-gray-base text-sm uppercase font-gm'}>STAY IN TOUCH:</label>
                    <div className={'flex justify-between mt-3.5 border-b border-solid border-gray-base '}>
                        <input className={'w-full pr-5 pb-2.5 font-gr text-sm placeholder-gray-base bg-green-mustard text-gray-base leading-none outline-none'} placeholder={'enter email'}/>
                        <Button tail={'pb-2.5 text-sm uppercase font-medium text-gray-base'} type={'submit'} text={'Submit'} />
                    </div>
                </form> */}
            </div>
              <div className={'pt-6 md:pt-9 pb-20 md:pb-6 pl-5 md:pl-80 xlg:border-t border-solid border-gray-400 pr-5 md:pr-80'}>
                <div className={'max-w-screen-2xl m-auto w-full flex flex-wrap flex-col-reverse xlg:flex-row justify-between gap-4 sm:gap-2.5'}>
                  <span className={'text-center xlg:text-left font-gm text-xs text-gray-base w-full md:w-auto md:order-none'}>{copyright}</span>
                  <div className={'flex justify-center xlg:justify-start xlg:flex-wrap gap-10 w-full md:w-auto md:order-none'}>
                      <a className={'items-center md:w-auto font-gm text-xs text-gray-base'} href={privacyLink} target='_blank' rel="noreferrer">Privacy</a>
                      <a className={'font-gm text-xs text-gray-base sm:mt-0'} href={termsLink} target='_blank' rel="noreferrer">Terms</a>
                  </div>
              </div>
            </div>
        </footer>
    );
}

export default Footer;
