import useContentful from '../api/useContentful';

export default function useFooter() {
	const { data, isLoading } = useContentful('2fiZfW6fUTxB0Q4Wej03iv');

  const description = data?.description || '';
  const logo = data?.logo || '';
  const twitterImg = data?.twitterImage || '';
  const instagramImg = data?.instagramImage || '';
  const linkedinImg = data?.linkedinImage || '';
	const socialTitle = data?.socialTitle || '';
	const twitterLink = data?.twitterLink || '';
	const instagramLink = data?.instagramLink || '';
	const linkedinLink = data?.linkedinLink || '';
	const copyright = data?.copyright || '';
	const privacyLink = data?.privacyLink || '';
	const termsLink = data?.termsLink || '';
	
	return {
		description,
    logo,
    twitterImg,
		twitterLink,
    instagramImg,
		instagramLink,
    linkedinImg,
		linkedinLink,
		socialTitle,
		copyright,
		privacyLink,
		termsLink,
		isLoading
	};
}
