import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';


//components
import Footer from './components/footer/footer';
const HomeContainer = React.lazy(() => import('./containers/home'));

function App() {
    return (
          <Suspense fallback={<span>Loading...</span>}>
                <Router>
                    <div className={'app-container flex flex-col h-screen justify-between'}>
                        <Switch>
                            <Route path='/'>
                                <HomeContainer />
                            </Route>
                        </Switch>

                        <Footer />
                    </div>
                </Router>
          </Suspense>
    );
}

export default App;
